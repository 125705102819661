// import { useState, useEffect } from 'react';
import {
	Popover,
	Grid,
	Avatar,
	Typography,
	Divider,
	Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMsal, useAccount } from '@azure/msal-react';
// import { setCurrentRole } from '../../Store/roleSlice';
import CustomButton from '../../CommonComponents/Button/CustomButton';

const AccountSettings = (props) => {
	// const dispatch = useDispatch();
	const { accounts, instance } = useMsal();
	const account = useAccount(accounts?.[0] || {});
	// const currentRole = useSelector((state) => state?.role?.currentRole);
	// const user_info = useSelector((state) => state?.auth?.user);
	const userName = props?.userName?.split(' ');
	// const roles = user_info?.accessMatrix?.map((matrix, index) => {
	// 	return { id: index + 1, name: matrix?.role };
	// });
	const id = props.open ? 'account-popover' : undefined;

	// const handleRoleChange = (role) => {
	// 	setRole(role);
	// 	dispatch(setCurrentRole(role));
	// };

	const signOutClickHandler = () => {
		let idToken = localStorage.getItem('AccessToken');
		const logoutRequest = {
			account: instance.getAccountByHomeId(account.homeAccountId),
			// mainWindowRedirectUri: '/coss',
			idTokenHint: idToken,
			postLogoutRedirectUri: '/coss',
		};
		instance.logoutRedirect(logoutRequest);
	};

	return (
		<div>
			<Popover
				id={id}
				open={props.open}
				anchorEl={props.anchorEl}
				onClose={props.handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Grid
					container
					direction={'column'}
					sx={{ width: '16rem', padding: '1rem', paddingRight: '0' }}
					rowGap={1}
				>
					{/* Grid that holds profile pic, username and email */}
					<Grid item>
						<Grid
							container
							direction={'row'}
							columnGap={1}
							alignItems={'center'}
						>
							<Grid item>
								<Grid container direction={'column'}>
									<Grid item>
										<Typography
											sx={{
												color: '#000',
												fontSize: '.875rem',
												fontStyle: 'normal',
												fontWeight: 700,
												lineHeight: '1.5rem',
												paddingTop: '.75rem',
												display: 'flex',
												letterSpacing: '0.00625rem',
											}}
										>
											{userName?.length ? userName[0] : ''}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											sx={{
												color: '#000',
												fontSize: '.7rem',
												fontStyle: 'normal',
												fontWeight: 400,
												lineHeight: '1.rem',
												display: 'flex',
												letterSpacing: '0.00625rem',
											}}
										>
											{props.email}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* Grid that holds profile pic, username and email end here  */}

					<Grid item>
						<Divider sx={{ width: '13rem', height: '0.0625rem' }} />
					</Grid>

					<Grid item>
						<CustomButton
							text="logout"
							width="10em"
							marginLeft="0em"
							handleClick={signOutClickHandler}
						/>
					</Grid>
				</Grid>
			</Popover>
		</div>
	);
};

AccountSettings.propTypes = {
	open: PropTypes.any,
	anchorEl: PropTypes.any,
	handleClose: PropTypes.any,
	email: PropTypes.any,
	userName: PropTypes.any,
};
export default AccountSettings;
