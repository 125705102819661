import { useEffect, useState } from "react";
import { getProjects } from "../../Services/ProjectService";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Table,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CustomButton from "../Button/CustomButton";

const ProjectTable = (props) => {
  const columnHeader = props?.columnHeader;
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getProjects(columnHeader)
      .then((data) => {
        setTableData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Grid container direction={"column"} gap={2}>
      <Grid item>
        <TableContainer
          sx={{
            width: "94%",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "2rem",
            maxHeight: "20rem",
            "&::-webkit-scrollbar": { width: "12px", height: "12px" },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              margin: "40px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              borderRadius: "1rem",
            },
            "&::-webkit-scrollbar-thumb:hover": { background: "#888" },
          }}
        >
          <Table sx={{ overflowY: "scroll" }}>
            <TableHead sx={{ background: "#c4c4c4" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                  }}
                >
                  {columnHeader}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      {data}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sx={{ paddingLeft: "2rem" }}>
        <FormControl>
          <RadioGroup row>
            <FormControlLabel
              value={"new"}
              control={<Radio />}
              label="Tranfer to new project"
            />
            <FormControlLabel
              value={"existing"}
              control={<Radio />}
              label="Tranfer to existing project"
            />
            <FormControlLabel
              value={"no"}
              control={<Radio />}
              label="No tranfer to project"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <CustomButton text={"Submit"} width={"8rem"} />
      </Grid>
      <br />
    </Grid>
  );
};
export default ProjectTable;
