import { useState } from "react";
import { Tabs, Tab, styled, createTheme } from "@mui/material";
import MaintenanceTable from "../../Maintenance/MaintenanceTable";
import { Home, TrendingUp } from "react-feather";
import HomeContent from "./HomeContent";
import TrendsHome from "../Trends/TrendsHome";

const HomePage = () => {
  const [showTable, setShowTable] = useState(true);
  const [selectedTab, setSelectedTab] = useState("home");
  const closeDialog = () => {
    setShowTable(false);
  };
  const theme = createTheme({
    components: {
      Dialog: {
        root: {
          fontSize: "1rem",
          background: "whitesmoke",
        },
      },
    },
  });
  const StyledTab = styled(Tab)(() => ({
    display: "flex",
    maxWidth: "100%",
    fontFamily: "Roboto",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 400,
    width: "5%",
    margin: "0",
    height: "4.5rem",
    letterSpacing: "0px",
    textTransform: "none",
    justifyContent: "center",
    gap: "1.75rem",
    alignItems: "center",
    flexShrink: 0,
    background: "#000",
    color: "#fff",
    "&.Mui-selected": {
      color: "#0888BF",
      // background:"#0888BF"
    },
    "&:hover": {
      background: "#707070",
      color: "#fff",
    },
  }));
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    [theme.breakpoints.only("md")]: {
      marginLeft: "4rem",
    },
  }));
  return (
    <div>
      <MaintenanceTable closeDialog={closeDialog} open={showTable} />
      {showTable === false && (
        <div style={{ paddingTop: "4rem" }}>
          <StyledTabs
            value={selectedTab}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            onChange={(event, value) => {
              setSelectedTab(value);
            }}
          >
            <StyledTab value="home" icon={<Home size={35} />} />
            <StyledTab value="trending" icon={<TrendingUp size={35} />} />
          </StyledTabs>
          <br />
          {selectedTab === "home" && <HomeContent />}
          {selectedTab === "trending" && <TrendsHome />}
        </div>
      )}
    </div>
  );
};

export default HomePage;
