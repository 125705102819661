export const getProjects = async (category) => {
  if (category === "REMAN") {
    return [
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual reman (REMAN)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual reman (REMAN)",
    ];
  } else if (category === "PSEUDO") {
    return [
      "PartNumber A000430539570 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A000430539570 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A000460638070 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A001431711470 of type MARKETING does not fit to project residual PSEUDO (PSEUDO)",
      "PartNumber A002460518070 of type PSEUDO does not fit to project residual PSEUDO (PSEUDO)",
    ];
  } else {
    return [
      {
        name: "HDEP Inejktoren CRIN 4, Gen 2",
        active: true,
      },
      {
        name: "HDEP Inejktoren CRIN 4, Gen 3",
        active: false,
      },
    ];
  }
};
export const getHomeList = async () => {
  return [
    {
      project: "residual pseudo",
      intiator: "default",
      type: "P",
      deviationStockValue: "41,841.00",
      targetStockPep: "0",
      deviationPep: "297",
      fulfillmentRate: "0%",
      fulfillmentRateTarget: "0%",
      predictedDemandCores: "",
      predictedDemandMissing: "",
      predictedFulfilmentRate: "",
    },
    {
      project: "residual reman",
      intiator: "default",
      type: "E",
      deviationStockValue: "",
      targetStockPep: "",
      deviationPep: "",
      fulfillmentRate: "",
      fulfillmentRateTarget: "",
      predictedDemandCores: "307089",
      predictedDemandMissing: "-220141",
      predictedFulfilmentRate: "28%",
    },
    {
      project: "Starter ATEGO",
      intiator: "stepsch",
      type: "E",
      deviationStockValue: "41,841.00",
      targetStockPep: "0",
      deviationPep: "297",
      fulfillmentRate: "0%",
      fulfillmentRateTarget: "0%",
      predictedDemandCores: "556",
      predictedDemandMissing: "100",
      predictedFulfilmentRate: "118%",
    },
    {
      project: "residual pseudo",
      intiator: "default",
      type: "P",
      deviationStockValue: "41,841.00",
      targetStockPep: "0",
      deviationPep: "297",
      fulfillmentRate: "0%",
      fulfillmentRateTarget: "0%",
      predictedDemandCores: "",
      predictedDemandMissing: "",
      predictedFulfilmentRate: "",
    },
    {
      project: "residual pseudo",
      intiator: "default",
      type: "P",
      deviationStockValue: "41,841.00",
      targetStockPep: "0",
      deviationPep: "297",
      fulfillmentRate: "0%",
      fulfillmentRateTarget: "0%",
      predictedDemandCores: "",
      predictedDemandMissing: "",
      predictedFulfilmentRate: "",
    },
  ];
};
export const getTrendingList = async () => {
  return [
    {
      project: "residual pseudo",
      qty: 503,
      june: [80, 15],
      july: [75, 12],
      august: [85, 10],
      september: [88, 13],
      october: [90, 14],
      november: [92, 11],
    },
    {
      project: "residual reman",
      qty: 100028,
      june: [70, 18],
      july: [80, 14],
      august: [90, 11],
      september: [85, 12],
      october: [88, 13],
      november: [91, 10],
    },
    {
      project: "DPF Tracking",
      qty: 3,
      june: [80, 15],
      july: [75, 12],
      august: [85, 10],
      september: [88, 13],
      october: [90, 14],
      november: [92, 11],
    },
    {
      project: "SWR Retarder",
      qty: 3,
      june: [70, 18],
      july: [80, 14],
      august: [90, 11],
      september: [85, 12],
      october: [88, 13],
      november: [91, 10],
    },
    {
      project: "Starter ATEGO",
      qty: 2,
      june: [80, 15],
      july: [75, 12],
      august: [85, 10],
      september: [88, 13],
      october: [90, 14],
      november: [92, 11],
    },
    {
      project: "Starter Valeo",
      qty: 2,
      june: [70, 18],
      july: [80, 14],
      august: [90, 11],
      september: [85, 12],
      october: [88, 13],
      november: [91, 10],
    },
  ];
};
