import { useEffect, useState } from "react";
import { getProjects } from "../../Services/ProjectService";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Table,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CustomButton from "../Button/CustomButton";

const EditProjectTable = (props) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getProjects()
      .then((data) => {
        setTableData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Grid container direction={"column"} gap={2}>
      <Grid item>
        <TableContainer
          sx={{
            width: "94%",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "2rem",
            maxHeight: "20rem",
            "&::-webkit-scrollbar": { width: "12px", height: "12px" },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              margin: "40px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              borderRadius: "1rem",
            },
            "&::-webkit-scrollbar-thumb:hover": { background: "#888" },
          }}
        >
          <Table sx={{ overflowY: "scroll" }}>
            <TableHead sx={{ background: "#c4c4c4" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                  }}
                >
                  {
                    "List of Projects having Stock Target Date less than Current Date"
                  }
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                  }}
                >
                  {"Status"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <Radio />
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      {data?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <FormControl>
                        <RadioGroup row>
                          <FormControlLabel
                            value={"new"}
                            control={<Radio />}
                            label="Active"
                            defaultChecked={
                              data?.active === true ? true : false
                            }
                          />
                          <FormControlLabel
                            value={"existing"}
                            control={<Radio />}
                            label="Inactive"
                            defaultChecked={
                              data?.active === true ? true : false
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <CustomButton text={"Edit Project"} />
      </Grid>
    </Grid>
  );
};
export default EditProjectTable;
