import { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
// import { useSelector } from "react-redux";
import Router from "./Router";
import { loginRequest } from "./auth.config";
// import { getUserInfo } from "./Services/UserService";
// import { login } from "./Store/authSlice";

const Pages = () => {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const account = useAccount(accounts[0] || null);
  // const user = useSelector((state) => state?.auth?.user);
  // const [unauthorized, setUnauthorized] = useState(false);

  async function callHandleRedirectPromise(instanceObj) {
    await instanceObj.handleRedirectPromise;
  }
  useEffect(() => {
    callHandleRedirectPromise(instance);
    console.log("Instance", instance);
    console.log("Account", account);
    if (account) {
      localStorage.setItem("AccessToken", account.idToken);
      // if (!user) {
      //   // getUserInfo()
      //   //   .then((userInfo) => {
      //   //     dispatch(login(userInfo));
      //   //     const currentRole = userInfo?.userRole;
      //   //     dispatch(setCurrentRole(currentRole));
      //   //   })
      //   //   .catch((err) => {
      //   //     console.warn(err);
      //   //     if (err?.response?.status === 401) {
      //   //       // setUnauthorized(true);
      //   //     } else if (err?.response?.status === 403) {
      //   //       setForbidden(true);
      //   //     }
      //   //   });
      //   console.log(user);
      // }
    }
  }, [account, instance]);

  const handleRedirect = () => {
    instance
      .loginRedirect({ ...loginRequest, prompt: "login" })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <UnauthenticatedTemplate>
        {activeAccount?.idToken !== undefined ? null : handleRedirect()}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {account !== null ? (
          <Router />
        ) : (
          <h1>
            {
              "You don't have permission to access COSS. Please contact the admin"
            }
          </h1>
        )}
      </AuthenticatedTemplate>
    </div>
  );
};

export default Pages;
