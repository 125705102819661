import { useEffect, useState } from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Filter } from "react-feather";
import CustomButton from "../../CommonComponents/Button/CustomButton";
import { ReactComponent as ExcelIcon } from "../../Assets/file-excel-solid.svg";
import TrendsTable from "./TrendsTable";
import { getTrendingList } from "../../Services/ProjectService";

const TrendsHome = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getTrendingList().then((result) => {
      setData(result);
    });
  }, []);
  const columns = [
    {
      id: 1,
      key: "project",
      label: "Project",
      subHeaders: [],
    },
    {
      id: 2,
      key: "qty",
      label: "QTY Parts",
      subHeaders: [],
    },
    {
      id: 3,
      key: "june",
      label: "June 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
    {
      id: 4,
      key: "july",
      label: "July 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
    {
      id: 5,
      key: "august",
      label: "August 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
    {
      id: 6,
      key: "september",
      label: "September 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
    {
      id: 7,
      key: "october",
      label: "October 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
    {
      id: 8,
      key: "november",
      label: "November 2024",
      subHeaders: ["Fullfilment Rate%", "Return Rate%"],
    },
  ];
  return (
    <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
      <Grid
        container
        direction={"column"}
        rowGap={5}
        sx={{
          background: "#fff",
          paddingLeft: "2rem",
          paddingTop: "1rem",
          minHeight: "30rem",
          width: "90rem",
        }}
      >
        <Grid item sx={{ paddingRight: "2rem" }}>
          <Grid container direction={"row"} columnGap={2} rowGap={4}>
            <Grid item>
              <CustomButton text={"All"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Pseudo"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Reman"} width={"5rem"} />
            </Grid>
            <Grid item sx={{ alignItems: "flex-end" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton sx={{ display: "contents" }}>
                  <Filter size={30} color="#000" />
                </IconButton>
                <IconButton sx={{ display: "contents" }}>
                  <ExcelIcon width="30" height="30" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingRight: "2rem" }}>
          <TrendsTable colheaders={columns} tableData={data} />
        </Grid>
      </Grid>
    </div>
  );
};
export default TrendsHome;
