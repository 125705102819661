import { useState } from "react";
import "./Header.css";
import { useTranslation } from "react-i18next";
import Logo from "../../Assets/mercedes-benz-logo.png";

import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Drawer,
  Typography,
  Avatar,
} from "@mui/material";
import AccountSettings from "./AccountSettings";

const Header = () => {
  const { t } = useTranslation();
  const [openSettings, setOpenSettings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileClick = (event) => {
    setOpenSettings(true);
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setOpenSettings(false);
    setAnchorEl(null);
  };

  return (
    <div className="headerWrapper">
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          style={{
            background: "#000",
            color: "#6A6A75",
            height: "4rem",
          }}
        >
          <Toolbar>
            <Typography noWrap>
              <div className="logo">
                <img src={Logo} alt={"Logo"} className="headerLogo" />
              </div>
            </Typography>
            <Typography
              sx={{
                color: "#C8C8C8",
                position: "relative",
                left: "10%",
                fontSize: "1.3rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "2.5rem",
                top: ".5rem",
              }}
            >
              CoSS
            </Typography>

            <Box sx={{ flexGrow: 1, alignItems: "center" }} />

            <IconButton aria-label="avatar" onClick={handleProfileClick}>
              <Avatar alt="Channabasu" src="/" />
            </IconButton>
            <AccountSettings
              anchorEl={anchorEl}
              open={openSettings}
              handleClose={handleProfileClose}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Header;
