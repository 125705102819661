import { useEffect, useState } from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Filter, PlusSquare } from "react-feather";
import CustomButton from "../../CommonComponents/Button/CustomButton";
import CustomTextField from "../../CommonComponents/TextField/CustomTextField";
import { ReactComponent as ExcelIcon } from "../../Assets/file-excel-solid.svg";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import { getHomeList } from "../../Services/ProjectService";

const HomeContent = () => {
  const [data, setData] = useState([]);
  const columns = [
    {
      id: 7,
      key: "project",
      label: "Project Name",
    },
    {
      id: 8,
      key: "intiator",
      label: "Initiator",
    },
    {
      id: 9,
      key: "type",
      label: "Type",
    },
    {
      id: 1,
      key: "deviationStockValue",
      label: "Deviation Stockvalue",
    },
    {
      id: 2,
      key: "targetStockPep",
      label: "Targeted Stock PEP-R",
    },
    {
      id: 3,
      key: "deviationPep",
      label: "Deviation PEP-R to Stock",
    },
    {
      id: 4,
      key: "fulfillmentRate",
      label: "Fulfillment Rate[6 Months]",
    },
    {
      id: 5,
      key: "fulfillmentRateTarget",
      label: "Fulfillment Rate Target Date",
    },
    {
      id: 6,
      key: "predictedDemandCores",
      label: "Predicted Demand Cores/70",
    },
    {
      id: 10,
      key: "predictedDemandMissing",
      label: "Predicted Missing Demand",
    },
    {
      id: 11,
      key: "predictedFulfilmentRate",
      label: "Predicted Fulfilment Rate",
    },
  ];
  useEffect(() => {
    getHomeList()
      .then((result) => {
        setData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
      <Grid
        container
        direction={"column"}
        rowGap={5}
        sx={{
          background: "#fff",
          paddingLeft: "2rem",
          paddingTop: "1rem",
          minHeight: "30rem",
          width: "90rem",
        }}
      >
        <Grid item sx={{ paddingRight: "2rem" }}>
          <Grid container direction={"row"} columnGap={2} rowGap={4}>
            <Grid item>
              <CustomButton text={"All"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Pseudo"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Reman"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Refresh"} width={"5rem"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Export Forecast XLS"} width={"auto"} />
            </Grid>
            <Grid item>
              <CustomButton text={"Export Forecast CSV"} width={"auto"} />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>{"Search"}</Typography> &nbsp;
              <CustomTextField width={"10rem"} height={"1.5rem"} />
            </Grid>
            {/* <Grid item>
              <CustomDropDown />
            </Grid> */}
            <Grid item>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton sx={{ display: "contents" }}>
                  <Filter size={30} color="#000" />
                </IconButton>
                <IconButton sx={{ display: "contents" }}>
                  <ExcelIcon width="30" height="30" />
                </IconButton>
                <IconButton sx={{ display: "contents" }}>
                  <PlusSquare size={30} color="#000" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingRight: "2rem" }}>
          <CustomTable colheaders={columns} tableData={data} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeContent;
