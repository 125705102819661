import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Trash2 } from "react-feather";

const TrendsTable = (props) => {
  const colheaders = props?.colheaders;
  const width = props?.width ? `${props?.width}rem` : "auto";
  return (
    <Grid>
      <Grid item>
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
            "&::-webkit-scrollbar": { width: "12px", height: "12px" },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              margin: "40px",
              boxShadow: "inset 7px 10px 12px #f0f0f0",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              borderRadius: "1rem",
            },
            "&::-webkit-scrollbar-thumb:hover": { background: "#888" },
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#dddddd54" }}>
              <TableRow>
                {/* <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ccc",
                  }}
                ></TableCell> */}
                {colheaders?.map((header) => (
                  <TableCell
                    align="center"
                    key={header.key}
                    sx={{
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                    colSpan={header.subHeaders.length > 0 ? 2 : 1}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {/* <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ccc",
                  }}
                ></TableCell> */}
                {colheaders.map((column) =>
                  column.subHeaders.length > 0 ? (
                    column.subHeaders.map((subHeader, index) => (
                      <TableCell
                        key={`${column.key}-subheader-${index}`}
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {subHeader}
                      </TableCell>
                    ))
                  ) : (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        borderRight: "1px solid #ccc",
                      }}
                    ></TableCell> // Empty cell if no subheader
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.tableData?.length === 0 ? (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              ) : (
                props?.tableData?.map((row, index) => (
                  <TableRow hover key={index}>
                    {colheaders.map((column) =>
                      column.subHeaders.length > 0 ? (
                        column.subHeaders.map((subHeader, subIndex) => (
                          <TableCell
                            key={`${column.key}-row-${index}-subheader-${subIndex}`}
                            align="center"
                            sx={{
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {row[column.key][subIndex]}
                          </TableCell>
                        ))
                      ) : (
                        <TableCell
                          key={`${column.key}-row-${index}`}
                          align="center"
                          sx={{
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {row[column.key]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <br />
    </Grid>
  );
};
export default TrendsTable;
