import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTextField = (props) => {
	const styles = {
		textField: {
			'& .MuiTextField-root': {
				width: `${props.width}`,
			},
			'& .MuiInputBase-root': {
				color: '#000',
				background: '#fff',
				flexShrink: 0,
				borderRadius: '.175rem',
				border: ' 1px #E5E5E5',
				width: `${props.width}`,
				height: `${props.height}`,
				marginLeft: `${props.marginLeft}` || 0,
				marginRight: `${props.marginRight}` || 0,
			},

			'& input::placeholder': {
				fontSize: '14px',
			},
		},
	};

	return (
		<>
			<TextField
				data-testid="text-field"
				type="text"
				name={props.name}
				id={props.id}
				onChange={props.handleChange}
				autoComplete="off"
				multiline={props.multiline}
				row={1 || props.row}
				placeholder={props?.placeholder}
				sx={styles.textField}
				error={props.error}
				defaultValue={props?.defaultValue}
				disabled={props?.disabled}
				fullWidth
			/>
		</>
	);
};

CustomTextField.propTypes = {
	width: PropTypes.any,
	height: PropTypes.any,
	name: PropTypes.any,
	id: PropTypes.any,
	value: PropTypes.any,
	handleChange: PropTypes.any,
	multiline: PropTypes.any,
	placeholder: PropTypes.any,
	marginLeft: PropTypes.any,
	marginRight: PropTypes.any,
	error: PropTypes.any,
};
export default CustomTextField;
