import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./Modules/Header/Header";
import HomePage from "./Modules/Home/HomePage";

const Router = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/coss" element={<HomePage />} />
          <Route path="/" element={<Navigate to="/coss" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default Router;
