import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Trash2 } from "react-feather";

const CustomTable = (props) => {
  const colheaders = props?.colheaders;
  const width = props?.width ? `${props?.width}rem` : "auto";
  return (
    <Grid>
      <Grid item sx={{ width: width }}>
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
            "&::-webkit-scrollbar": { width: "12px", height: "12px" },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              margin: "40px",
              boxShadow: "inset 7px 10px 12px #f0f0f0",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              borderRadius: "1rem",
            },
            "&::-webkit-scrollbar-thumb:hover": { background: "#888" },
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#dddddd54" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ccc",
                  }}
                >
                  <Trash2 />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid #ccc",
                  }}
                >
                  {"Actions"}
                </TableCell>
                {colheaders?.map((header) => (
                  <TableCell
                    align="center"
                    key={header.key}
                    sx={{
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.tableData?.length === 0 ? (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              ) : (
                props?.tableData?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid #ccc",
                      }}
                    >
                      <Checkbox />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid #ccc",
                      }}
                    ></TableCell>
                    {colheaders.map((header) => (
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {data[header.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <br />
    </Grid>
  );
};

export default CustomTable;
