import { useState } from "react";
import {
  createTheme,
  Dialog,
  DialogContent,
  ThemeProvider,
  Tabs,
  Tab,
  styled,
} from "@mui/material";
import { X } from "react-feather";
import ProjectTable from "../CommonComponents/Table/ProjectTable";
import EditProjectTable from "../CommonComponents/Table/EditProjectTable";
// import { TabContext, TabPanel } from "@mui/lab";

const theme = createTheme({
  components: {
    Dialog: {
      root: {
        fontSize: "1rem",
        background: "whitesmoke",
      },
    },
  },
});
const StyledTab = styled(Tab)(() => ({
  display: "flex",
  maxWidth: "100%",
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 400,
  width: "32%",
  margin: "0",
  height: "4.5rem",
  letterSpacing: "0px",
  textTransform: "none",
  justifyContent: "center",
  gap: "1.75rem",
  alignItems: "center",
  flexShrink: 0,
  background: "#fff",
  "&.Mui-selected": {
    background: "#000",
    color: "#fff",
  },
  "&:hover":{
    background: "#707070",
    color: "#fff",
  }
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "1rem",
  paddingLeft: "2rem",
  [theme.breakpoints.only("md")]: {
    marginLeft: "4rem",
  },
}));

const MaintenanceTable = (props) => {
  const [selectedTab, setSelectedTab] = useState("reman");
  return (
    <div style={{ overflowY: "hidden" }}>
      <ThemeProvider theme={theme}>
        <Dialog
          open={props?.open}
          closeDailog={props?.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fontFamily="DaimlerCS-Regular"
          PaperProps={{
            style: {
              backgroundColor: "#fff",
              alignItems: "left",
              width: "70rem",
              height: "auto",
              minHeight: "40rem",
              maxWidth: "70rem",
              border: "1px solid",
              borderColor: "#E5E5E5",
              background: "#FFFFFF",
              position: "relative",
              overflowY: "hidden",
            },
          }}
        >
          <X
            onClick={props?.closeDialog}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "25px",
              right: "26px",
              zIndex: "99",
            }}
          />
          <br />
          <br />
          <DialogContent sx={{ padding: 0, overflowY: "hidden" }}>
            {/* <TabContext
              value={selectedTab}
              sx={{ justifyContent: "space-between" }}
            > */}
            <StyledTabs
              value={selectedTab}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              onChange={(event, value) => {
                setSelectedTab(value);
              }}
            >
              <StyledTab
                value="reman"
                label="Reman"
                sx={{
                  color: "#4f4f4f",
                  background: "#c4c4c4",
                }}
              />
              <StyledTab
                value="pseudo"
                label="Pseudo"
                sx={{
                  color: "#4f4f4f",
                  background: "#c4c4c4",
                }}
              />
              <StyledTab
                value="projects"
                label="Projects"
                sx={{
                  color: "#4f4f4f",
                  background: "#c4c4c4",
                }}
              />
            </StyledTabs>
            <br />
            {selectedTab === "reman" && <ProjectTable columnHeader="REMAN" />}
            {selectedTab === "pseudo" && <ProjectTable columnHeader="PSEUDO" />}
            {selectedTab === "projects" && <EditProjectTable />}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
export default MaintenanceTable;
